import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faCheck } from "@fortawesome/free-solid-svg-icons";
import { scrollToSection } from "../../utils/ScrollToSection";

export default function AboutSection() {
  return (
    <Container id="about" fluid className="container-fluid pt-0 mb-5">
      <Container className="pt-5 ">
        <Row className="g-5">
          <Col
            lg={6}
            className="align-self-center mb-md-5 pb-md-5 wow fadeIn"
            data-wow-delay="0.3s"
          >
            <h1 className="mb-4">The BeYou Corporate Story</h1>
            <p className="mb-4">
              In every thread, stitch, and color, BeYou Corporate seamlessly
              blends your brand's essence with top-tier craftsmanship. Our
              journey began with a simple yet potent belief: uniforms are not
              mere fabrics but powerful symbols of unity, professionalism, and
              brand identity. Whether you’re a burgeoning startup or a
              multinational conglomerate, our expertise in curating specialized
              uniform solutions is unparalleled.
            </p>
            <a
              onClick={() => {
                scrollToSection("contact");
              }}
              className="btn btn-outline-dark py-sm-3 px-sm-5 rounded-pill animated slideInRight"
            >
              Contact Us
            </a>

            <Row className="g-4 pt-3">
              <Col sm={6}>
                <div
                  className="d-flex rounded p-3"
                  style={{ background: "rgba(256, 256, 256, 0.1)" }}
                >
                  <FontAwesomeIcon
                    className="fa-icon"
                    icon={faUsers}
                    size="3x"
                  />
                  <div className="ms-3">
                    <h2 className="mb-0" data-toggle="counter-up">
                      47
                    </h2>
                    <p className="mb-0">Happy Clients</p>
                  </div>
                </div>
              </Col>

              <Col sm={6}>
                <div
                  className="d-flex rounded p-3"
                  style={{ background: "rgba(256, 256, 256, 0.1)" }}
                >
                  <FontAwesomeIcon
                    className="fa-icon"
                    icon={faCheck}
                    size="3x"
                  />
                  <div className="ms-3">
                    <h2 className="mb-0" data-toggle="counter-up">
                      62
                    </h2>
                    <p className="mb-0">Completed Projects</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            lg={6}
            className="justify-content-center d-flex text-center text-md-end wow fadeIn"
            data-wow-delay="0.5s"
          >
            {/* <video style={{ borderRadius: "10px" }} controls width="100%">
              <source src="../assets/videos/2.mp4" type="video/mp4" />
              Sorry, your browser doesn't support videos.
            </video> */}

            <img
              style={{ borderRadius: "10px" }}
              className="img-fluid"
              src="../assets/images/shirts/shirtHanging.png"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
