import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

const FAQ = [
  {
    question: "What types of corporate clothing does BeYou Corporate offer?",
    answer:
      "We offer a wide range of corporate apparel, including suits, shirts, blouses, and specialized uniforms for various sectors, including hospitality, healthcare, and more.",
  },
  {
    question: "Can I customize the uniforms based on our brand guidelines?",
    answer:
      "Absolutely! Your brand is our blueprint. We work closely with clients to ensure that the uniforms resonate with their brand image and guidelines.",
  },
  {
    question: "How do you ensure the quality of the clothing?",
    answer:
      "Our commitment to quality is unwavering. We source premium materials and employ expert craftsmen to ensure durability, comfort, and style in every piece.",
  },

  {
    question: "Is there a minimum order quantity?",
    answer:
      "We cater to businesses of all sizes. For specific order quantities and any potential minimums, please reach out to our sales team.",
  },
  {
    question: "What is your turnaround time for bulk orders?",
    answer:
      "Turnaround times can vary based on the size and complexity of the order. We recommend contacting our team with specifics for an accurate estimate.",
  },
  {
    question: "Do you offer international shipping?",
    answer:
      "Yes, we provide shipping solutions to various international locations. Please contact us for details on shipping rates and times.",
  },
];

export default function FaqSection() {
  return (
    <Container className="container-fluid py-0">
      <Container className="container py-5">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s">
          <h1 className="mb-4">Frequently Asked Questions</h1>
        </div>
        <Row className="pt-3">
          <Col lg={6}>
            <Accordion flush>
              {FAQ.slice(0, 3).map((item, index) => (
                <Accordion.Item
                  className="wow fadeIn"
                  data-wow-delay={`0.${index}s`}
                  eventKey={`${index}`}
                >
                  <Accordion.Header>{item.question}</Accordion.Header>
                  <Accordion.Body>{item.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
          <Col lg={6}>
            <Accordion flush>
              {FAQ.slice(3, 6).map((item, index) => (
                <Accordion.Item
                  className="wow fadeIn"
                  data-wow-delay={`0.${index}s`}
                  eventKey={`${index}`}
                >
                  <Accordion.Header>{item.question}</Accordion.Header>
                  <Accordion.Body>{item.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
