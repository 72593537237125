import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const testimonialItems = [
  {
    quote:
      "Choosing BeYou Corporate for our team's apparel was a decision that brought us not just impeccable clothing but an elevation in our brand image. The meticulous attention to detail and their commitment to quality was apparent in every stitch. Our team has never looked better, and we feel unified, professional, and motivated. Thank you, BeYou Corporate, for transforming our corporate identity!",
    author: "Janet W.",
    title: "CEO at TechnoSync Solutions",
  },
  {
    quote:
      "BeYou Corporate goes beyond being a uniform provider — they are storytellers who weave our brand ethos into every fabric. Our uniforms speak for our values, our commitment to quality, and our respect for professionalism. It’s been a rewarding journey of uplifting our company’s presence, both among our clients and our dedicated staff.",
    author: "Harrison B.",
    title: "Operations Manager at GreenFields",
  },
  {
    quote:
      "As a real estate firm, our branding needs to be consistent, elegant, and make a statement. BeYou Corporate understood our needs with incredible depth and provided uniform solutions that truly represent who we are. The quality, design, and durability are unmatched. Our team proudly dons their uniforms, becoming brand ambassadors wherever they go.",
    author: "Liza R.",
    title: "Human Resources at CityGate Developers",
  },
  {
    quote:
      "In the hospitality sector, our attire needs to be as exemplary as our services. BeYou Corporate has been phenomenal in providing our staff with uniforms that are not only visually appealing but also incredibly comfortable and durable to cater to our dynamic work environment. Each uniform is a perfect blend of form and function!",
    author: "Anaya P.",
    title: "Head Chef at Fusion Bistro",
  },
  {
    quote:
      "In the logistics industry, our workforce is always on the move, and it’s crucial that their attire is both durable and representative of our brand. BeYou Corporate aced in providing uniform solutions that cater to our practical needs while ensuring a polished, professional look that significantly uplifts our brand image. Exceptional service, outstanding quality!",
    author: "Eli S.",
    title: "Owner of ClearPath Logistics",
  },
];

export default function TestimonialsSection() {
  const options = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    dots: true,
  };

  return (
    <Container fluid className="container-fluid values py-5">
      <Container className="py-5">
        <Row className="g-5">
          <Col lg={5} className="wow fadeIn" data-wow-delay="0.1s">
            <h1 className="mb-4">What Our Clients Say!</h1>
            <p className="mb-4">
              We take pride in the feedback from our clients, and we're always
              looking to improve on their comments.
            </p>
          </Col>

          <Col lg={7} className="wow fadeIn" data-wow-delay="0.5s">
            <OwlCarousel
              className="testimonial-carousel border-start owl-carousel"
              {...options}
            >
              {testimonialItems.map((item, index) => (
                <div key={index} className="testimonial-item ps-5">
                  <FontAwesomeIcon
                    className="fa-2x mb-3 fa-icon"
                    icon={faQuoteLeft}
                  />

                  <p>{item.quote}</p>
                  <div className="d-flex align-items-center">
                    <div className="ps-0">
                      <h5 className="mb-1">{item.author}</h5>
                      <span>{item.title}</span>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
