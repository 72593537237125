import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { scrollToSection } from "../../utils/ScrollToSection";

export default function WhyUsSection() {
  return (
    <Container
      id="whyus"
      style={{ backgroundColor: "#a09e9d26" }}
      fluid
      className="container-fluid mt-0 py-0"
    >
      <Container className=" py-lg-4 pb-4">
        <Row className="g-5 align-items-center">
          <Col lg={5} className="wow fadeIn why-choose" data-wow-delay="0.1s">
            <h1 className="mb-4">Why Choose BeYou Corporate?</h1>

            <div className="d-flex align-items-center mb-3">
              <div className="btn-sm-square text-primary rounded-circle me-1">
                👔
              </div>
              <span>Corporate Uniforms</span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="btn-sm-square text-primary rounded-circle me-1">
                🏭
              </div>
              <span>Industrial Wear</span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="btn-sm-square text-primary rounded-circle me-1">
                🍽️
              </div>
              <span>Hospitality Apparel</span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="btn-sm-square text-primary rounded-circle me-1">
                🏥
              </div>
              <span>Healthcare Uniforms</span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="btn-sm-square text-primary rounded-circle me-1">
                🏫
              </div>
              <span>School Uniforms</span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="btn-sm-square text-primary rounded-circle me-1">
                ➕
              </div>
              <span>And more...</span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="btn-sm-square text-primary rounded-circle me-1">
                🔗
              </div>
              <span>
                <a
                  onClick={() => {
                    scrollToSection("contact");
                  }}
                  style={{ cursor: "pointer", color: "#990011" }}
                >
                  Get in Touch
                </a>
                {""} with our styling consultants today!
              </span>
            </div>
          </Col>
          <Col lg={7} className="">
            <Row className="g-4">
              <Col md={6}>
                <Row className="g-4">
                  <Col sm={12} className="wow fadeIn" data-wow-delay="0.1s">
                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                      <div className="service-icon btn-square d-flex justify-content-center justify-item-center">
                        <i className="bi bi-heart fa-2x icon"></i>
                      </div>
                      <h5 className="mb-3">Tailored Fit</h5>
                      <p>Customized solutions to resonate with your brand.</p>
                    </div>
                  </Col>
                  <Col sm={12} className="wow fadeIn" data-wow-delay="0.5s">
                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                      <div className="service-icon btn-square">
                        <i className="bi bi-gem fa-2x icon"></i>
                      </div>
                      <h5 className="mb-3">Quality Craftsmanship</h5>
                      <p>
                        Premium materials that promise comfort and durability.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={6} className="pt-md-4 ">
                <Row className="g-4">
                  <Col
                    sm={12}
                    className="wow fadeIn pt-md-4"
                    data-wow-delay="0.3s"
                  >
                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                      <div className="service-icon btn-square">
                        <i className="bi bi-arrow-left-right fa-2x icon"></i>
                      </div>
                      <h5 className="mb-3">End-to-End Solutions</h5>
                      <p>From design to delivery, consider it done.</p>
                    </div>
                  </Col>
                  <Col sm={12} className="wow fadeIn " data-wow-delay="0.7s">
                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                      <div className="service-icon btn-square">
                        <i className="bi bi-lightbulb fa-2x icon"></i>
                      </div>
                      <h5 className="mb-3">Scalable Solutions</h5>
                      <p>
                        Catering to businesses of all sizes with equal fervor.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
