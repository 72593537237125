import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function OurValuesSection() {
  return (
    <Container id="values" fluid className="container-fluid values py-5">
      <Container className="">
        <Row className="g-5 align-items-center">
          <Col lg={6} className="wow fadeIn" data-wow-delay="0.5s">
            <img
              style={{ borderRadius: "10px" }}
              className="img-fluid"
              src="../assets/images/shirts/tie.png"
            />
          </Col>

          <Col lg={6} className="wow fadeIn" data-wow-delay="0.5s">
            <h1 className="mb-4">Our Values</h1>
            <p className="mb-4">
              At BeYou Corporate, our dedication runs deep. We're committed to
              delivering quality in every piece, ensuring durability, comfort,
              and impeccable style. Your brand serves as our foundational
              blueprint, guiding our designs and choices. Additionally, we
              prioritize sustainability, incorporating eco-friendly practices to
              contribute to a greener tomorrow.
            </p>

            <Row className="g-3">
              <Col sm={6}>
                <h6 className="mb-3">
                  <FontAwesomeIcon className="me-2 fa-icon" icon={faCheck} />
                  Commitment to Quality
                </h6>
                <h6 className="mb-3">
                  <FontAwesomeIcon className="me-2 fa-icon" icon={faCheck} />
                  Customer Centricity
                </h6>
                <h6 className="mb-3">
                  <FontAwesomeIcon className="me-2 fa-icon" icon={faCheck} />
                  Sustainability
                </h6>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
