import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const services = [
  {
    title: "Bespoke Designing",
    content:
      "Our design maestros create designs that mirror your brand's ethos and appeal, ensuring a blend of functionality and style.",
  },
  {
    title: "Precision Manufacturing",
    content:
      "Quality is stitched into the very fabric of our creations, promising not only aesthetics but also durability and comfort.",
  },
  {
    title: "Logistical Mastery",
    content:
      "From our floor to your door, experience impeccable, timely delivery, no matter the scale of your order.",
  },

  {
    title: "Post-Purchase Support",
    content:
      "Our relationship doesn’t end at delivery. Rely on us for steadfast after-sale support.",
  },
];

export default function OurServicesSection() {
  return (
    <Container id="services" fluid className="container-fluid services py-5">
      <Container className="">
        <Row className="g-5 align-items-center">
          <Col lg={6} className="wow fadeIn" data-wow-delay="0.5s">
            <h1 className="mb-4">Our Services</h1>
            <p className="mb-4">
              Holistic Uniform Solutions Tailored to Your Needs From Concept to
              Closet with BeYou Corporate
            </p>
            <Row className="g-4 pt-3">
              {services.map((item, index) => (
                <Col sm={6}>
                  <div
                    className="d-flex rounded p-3"
                    style={{ background: "#a09e9d" }}
                  >
                    <div className="ms-3">
                      <h4 className="text-white mb-2">{item.title}</h4>
                      <p className="text-white mb-0">{item.content}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>

          <Col lg={6} className="wow fadeIn" data-wow-delay="0.6s">
            <img
              className="img-fluid"
              src="../assets/images/shirts/smartmockups_lnsnsgm2.png"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
