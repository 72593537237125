import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function SecondSection() {
  return (
    <Container fluid className="py-5 wow fadeIn" data-wow-delay="0.1s">
      <Container className="text-center py-5">
        <Row className="justify-content-center">
          <Col lg={6}>
            <i className="bi bi-exclamation-triangle display-1"></i>
            <h1 className="display-1">404</h1>
            <h1 className="mb-4">Page Not Found</h1>
            <p className="mb-4">
              We’re sorry, the page you have looked for does not exist in our
              website! Maybe go to our home page or try to use a search?
            </p>
            <a
              style={{ backgroundColor: "#a09e9d", borderColor: "#a09e9d" }}
              className="btn btn-primary rounded-pill py-3 px-5"
              href="../"
            >
              Go Back To Home
            </a>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
